class Config {
  url =
    process.env.REACT_APP_API_BASE_URL ||
    (process.env.NODE_ENV === 'development' ? 'https://dev.glimra.com' : 'https://ipa.glimra.com');

  baseApiUrl = () => {
    return this.url;
  };
}

const singleton = new Config();
export default singleton;
